import * as React from "react"
import { Link, graphql } from "gatsby"

import { MdArrowForward } from "@react-icons/all-files/md/MdArrowForward"

import { Bio, Header, Seo } from "@/components/common"
import Layout from "@/components/Layout"
import { INode, PageProps } from "@/definitions"

const BlogIndex: React.FC<PageProps> = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.edges

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Recently added" />
      <h1 className="text-2xl mb-4">Recently added</h1>
      <ol className="divide-y divide-skin-fg-muted max-w-2xl">
        {posts.map(({ node }: { node: INode }, index: number) => {
          const post = node
          const classes = index === 0 ? "pb-6" : "py-6"
          return (
            <li key={node.fields.slug} className={classes}>
              <article itemScope itemType="http://schema.org/Article">
                <Link to={node.fields.slug} itemProp="url">
                  <Header
                    title={post.frontmatter.title || post.fields.slug}
                    date={post.frontmatter.date}
                  />
                </Link>
                <hr />
                <p
                  itemProp="description"
                  className="index-post-paragraph"
                >
                  <span dangerouslySetInnerHTML={{
                    __html: node.excerpt || node.frontmatter.description,
                  }}
                  />
                  <Link to={node.fields.slug} itemProp="url" className="read-more-link">read more<MdArrowForward /></Link>
                </p>
                {/* <section>
                  {(node.frontmatter.tags || "")
                    .split(",")
                    .map((s: string) => s.trim())
                    .map((s: string) => (
                      <span key={s}>{`#${s}`}</span>
                    ))}
                </section> */}
              </article>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 280)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`
